<template>
  <b-container class="my-4">
    <b-row class="text-center mb-4" align-h="center" v-if="loading">
      <b-col cols="1">
        <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card v-if="!loading">
          <b-container>
            <b-row>
              <b-col>
                <div v-for="item in items" :key="'item'+item.id" class="mb-5">
                  <div><strong>updated at: {{moment(item.current.data.updated_at).format('YYYY-MM-DD HH:mm')}} by {{item.current.data.updated_by}}</strong></div>
                  <h1><history-markup :current="item.current.data.name" :version="item.version.data.name" /></h1>
                  <history-markup :current="item.current.data.headline" :version="item.version.data.headline" />
                  <history-markup v-if="item.current.data.description" :current="item.current.data.description" :version="item.version.data.description" />
                </div>
              </b-col>
            </b-row>
          </b-container>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import moment from 'moment'
import HistoryMarkup from '@/components/HistoryMarkup.vue'

export default {
  name: 'ImpactInitiativeslog',
  components: {
    HistoryMarkup
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.$stat.log({ page: 'impact initiativelog', action: 'open impact initiativelog', model: 'impactinitiativeslog', model_id: this.$route.params.id })
    this.load()
  },
  data () {
    return {
      items: [],
      loading: true,
      moment: moment
    }
  },
  methods: {
    load: async function () {
      this.loading = true
      try {
        let apiName = 'cosmos'
        let path = `/impact/initiative/${this.$route.params.id}/log`
        const items = await this.$Amplify.API.get(apiName, path)
        const len = items.length
        const history = []
        items.forEach((element, index) => {
          element.data = JSON.parse(element.data)
          let version = {
            data: {
              name: '',
              headline: '',
              description: ''
            }
          }
          const next = index + 1
          if (next < len) {
            version = items[next]
          }
          const row = {
            id: element.id,
            current: element,
            version: version
          }
          history.push(row)
        })
        this.items = history
        console.log(this.items)
        this.loading = false
      } catch (e) {
        this.$logger.warn(e)
      }
    }
  }
}
</script>

<style scoped>

</style>
